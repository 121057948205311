<template>
  <b-container fluid>
    <UserList></UserList>
  </b-container>
</template>
<script>
import UserList from '@/components/admin/user/List'

export default {
  name: 'UserAdmin',
  components: { UserList }
}
</script>
